export const JBQContentCardIplaceBr = () => import('./jbq-content-card-iplace');
export const JBQIplaceBrHeader = () => import('./jbq-header-iplace');
export const JBQHeaderMultiLinkIplaceBr = () => import('./jbq-header-multi-link-iplace');
export const JBQHeroBannerIplaceBr = () => import('./jbq-hero-banner-iplace');
export const JBQHeroCarouselIplaceBr = () => import('./jbq-hero-carousel-iplace');
export const JBQCommonCarouselIplaceBr = () => import('./jbq-common-carousel-iplace');
export const JBQSpotlightCardIplaceBr = () => import('./jbq-spotlight-card-iplace');
export const JBQLearnMoreIplaceBr = () => import('./jbq-learn-more-iplace');
export const JBQBannerSideTextContainerIplaceBr = () => import('./jbq-banner-side-text-container-iplace');
export const JBQBannerSideTextIplaceBr = () => import('./jbq-banner-side-text-iplace');
